<template>
  <div class="summary-list-item">
    <dt class="title">
      {{ title }}
    </dt>
    <dd class="content">
      <slot />
    </dd>
  </div>
</template>

<script>
export default {
  name: 'CSummaryListItem',

  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-list-item {
  &:not(:last-child) {
    margin-bottom: 1em;
  }

  @media (min-width: 40rem) {
    display: grid;
    gap: 1em;
    grid-template-columns: 10em 1fr;
  }
}

.title {
  font-weight: 700;
}
</style>
