import { render, staticRenderFns } from "./CFormText.vue?vue&type=template&id=7668c00e&scoped=true&"
import script from "./CFormText.vue?vue&type=script&lang=js&"
export * from "./CFormText.vue?vue&type=script&lang=js&"
import style0 from "./CFormText.vue?vue&type=style&index=0&id=7668c00e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7668c00e",
  null
  
)

export default component.exports